<template>
  <div class="container">
      <iframe :src="url" frameborder="0" style="width: 100%; height: 100%;"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: `${window.location.origin}/enterprise/0f9991f1f7f446ffbd5c12eaa4636122`
    }
  },
  created() {
    // 获取是不是手机端
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      window.location.href = this.url
    }
  },
}
</script>

<style lang="less" scoped>
.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  iframe {
    max-width: 600Px;
    border: 1px solid #f0f0f0;
  }
}
</style>
